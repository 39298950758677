<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";


import Choices from "choices.js";
import SupplierAutoComplete from '@/components/supplier-auto-complete-input.vue'
import ExpenseAutoComplete from '@/components/fee-auto-complete-input.vue'


import {
    required,
} from "vuelidate/lib/validators";

import moment from 'moment'

import { getBrokerageApi } from "@/api/brokerage";
import { financial } from '@/api/misc'
import { getArApApi } from "@/api/cash";


export default {
    page: {
        title: "Create AP Western ",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },

    validations: {
        expense: {
            post_date: { required },
        },
    },


    data() {
        return {
            title: "Create Recoverable Exepnse",
            items: [
                {
                    text: "AP List",
                    href: "/ap/list",
                },
                {
                    text: "Create Recoverable Expense",
                    href: "/ap/list",
                },
            ],

            expense: {
                supplier_id: '',
                expense: 0,
                invoice: ''
            },

            dpconfig: {
                wrap: true,
                altInput: true,
                dateFormat: "Y-m",
                altFormat: "Y-m",
                ariaDateFormat: "Y-m",
            },
            dropzoneOptions: {
                url: "/AwsFileUpload/s3_upload_multi",
                thumbnailWidth: 150,
                maxFilesize: 50,
            },


            supplier_list: [],

     
            sub_office_choice : {},
            office_list: [],

            invoice_choice : {},
            invoice_list : [],

            fee_choice : {},
            fee_list : [],

            gl_entry_list: [],
            charge_agent_list: [],
            selected_agent: {},
            charge_agent_amount: 0,
            start_date: '',
            end_date: '',

            fee_amount : '',
            fee_gst    : '',
            current_fee : '',
            sub_office_name : '',
            sub_office_code : '',

            deduction_fee_list : [],
            total_amount: 0,
            invoice_id   : '',
            is_submitting: false,
          

            current_supplier: undefined,
            native_tmp_id   : 10234,

        }
    },

    components: {
        Layout,
        PageHeader,
        flatPickr,
       
        SupplierAutoComplete,
        ExpenseAutoComplete


    },

    methods: {

        f(v) {
            return financial(v, 2)
        },
        formSubmit() {

            this.$v.$touch();

            if (this.$v.$invalid == false) {
                this.is_submitting = true
                let data = {
                    amount       : Number(this.total_amount),
                    supplier_id  : this.expense.supplier_id,
                    payee_type   : 'SUPPLIER',
                    description  : this.expense.description,
                    post_date    : this.expense.post_date,
                    expense_details : this.gl_entry_list,
                    invoice_id      : this.invoice_id
                }
               

                getArApApi().create_recoverable_expense(data).then((res) => {
                    if (res.errCode == 0) {
                        this.$alertify.message("Post Succeed ");
                        this.$router.push({ name: 'ap-general' })
                    } else {
                        this.$alertify.error("Failed to Update " + res.errCode);
                    }
                    this.is_submitting = false
                })
            }
        },


        onExpenseSelected(evt) {
            this.current_fee = evt.fee
        },

       
        onAddFee() {
            let tmp_id = this.native_tmp_id
            let amount = financial(-Number(this.fee_amount), 2) 
            this.gl_entry_list.push({ gl_number: this.current_fee.accounting_gl,  gl_label: this.current_fee.name, amount: amount, tmp_id : tmp_id, exp_code : this.current_fee.code,  leg_b : 1 })

            if (this.current_fee.tax_flag && Number(this.fee_gst) != 0) {
                this.gl_entry_list.push({ gl_number: this.current_fee.accounting_gst_gl,  gl_label: this.current_fee.name, amount: -Number(this.fee_gst),  tmp_id : tmp_id, exp_code : this.current_fee.code, leg_b : 1 })
            }

            this.native_tmp_id += 1
        },

        onRemoveFee(idx) {
            this.gl_entry_list.splice(idx, 1)
        },


        onSupplierChanged(evt) {
            this.current_supplier = evt
            this.expense.supplier_id = evt.id
            this.gl_entry_list.push({ gl_number: this.current_supplier.accounting_gl, gl_label:this.current_supplier.name, amount: 0, leg_a : 1 })
            getArApApi().query_supplier_expense_list({supplier_id : evt.id}).then(res => {
                if (res.errCode == 0) {
                    this.invoice_choice.clearChoices()
                    this.invoice_list = []
                    res.data.map(e => {
                        this.invoice_list.push({
                            label : '$'+financial(Number(e.amount) + Number(e.gst), 2).toLocaleString() +' - INV:'+e.invoice,
                            value : e.id,
                            exp   : e,
                            total : financial(Number(e.amount) + Number(e.gst), 2)
                        })
                    })
                    this.invoice_choice.setChoices(this.invoice_list)
                }
                
            })
        
        },




        confirmToSubmit(fn, msg = 'Are you sure to submit?') {
            this.$alertify.confirmWithTitle(
                '', msg,
                () => {
                    this.formSubmit()
                },
                () => {
                }
            );
        },

    

        onInvoiceChange(evt) {
            let ivo_obj = this.invoice_list.find(e=> evt.detail.value == e.exp.id)
            this.total_amount  = ivo_obj.total
            this.invoice_id    = ivo_obj.exp.id
        },

        onOfficeChanged(evt) {
           
           let office_obj =  this.office_list.find(e => e.value == evt.detail.value)
           this.sub_office_name = office_obj.c.name
       }




    },


    created() {
        moment()
       


        getBrokerageApi().officeApi.list({ page: { page: 1, page_size: 50 } }).then((res) => {
            if (res.errCode == 0) {
                this.office_list = []
                res.data.map((s) => {
                    this.office_list.push({
                        label: s.branch_name,
                        value: s.office_code,
                        c: s
                    })
                })


                this.sub_office_choice.clearChoices()
                this.sub_office_choice.setChoices(this.office_list)
            }
        })


      



    },

    mounted() {
       
      
        this.sub_office_choice = new Choices('#choices-sub-office-input')

        this.invoice_choice = new Choices('#invoice_choice', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        })

        

    },


    watch: {
        total_amount  (new_value) {
            let supplier_gl =  this.gl_entry_list.find(e => e.gl_number == this.current_supplier.accounting_gl)
            if (supplier_gl) {
                supplier_gl.amount = Number(new_value)
            }
        },

        fee_amount (new_value) {
            this.fee_gst = financial(Number(new_value) * 0.05, 2)
        }
    },
}
</script>


<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="card">
            <div class="card-body">
                <form @submit.prevent="confirmToSubmit(formSubmit)">
                    <div class="row">

                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="choices-supplier-input" class="form-label">supplier</label>
                                <SupplierAutoComplete :init_data="{placeholder: 'Search Supplier'}" :query_type="'BROKERAGE'" @onSelected="onSupplierChanged" />
                        
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="mb-3">
                                <label for="choices-supplier-input" class="form-label">Invoice</label>
                                <select id="invoice_choice" class="form-control" @change="onInvoiceChange"></select>
                        
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="mb-3">
                                <label for="validationCustom03">Post Date</label>
                                <flat-pickr v-model="expense.post_date" placeholder="Select a date" class="form-control"
                                    :class="{ 'is-invalid': $v.expense.post_date.$error,  }"></flat-pickr>
                                <div v-if="$v.expense.post_date.$error" class="invalid-feedback">
                                    <span v-if="!$v.expense.post_date.required">This value is required.</span>
                                </div>
                            </div>
                        </div>

                     

                    </div>
                    <!-- end row-->

                    <div class="row">

                        <div class="col-md-8">
                            <div class="mb-3">
                                <label for="validationCustom01">Description</label>
                                <input id="validationCustom01" v-model="expense.description" type="text"
                                    class="form-control" placeholder="" value="" />
                            </div>
                        </div>

                    </div>
                    <!-- end row-->

                    <hr class="my-4" />


                    <div class="row">
                        <div class="col-md-12">
                            

                            <div class="row">
                                <div class="col-md-4">
                                    <div class="mb-3">
                                        <label for="choices-fee-input" class="form-label">Break Down GL</label>

                                        <ExpenseAutoComplete :s_type="'Expense'" @onSelected="onExpenseSelected"/>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="mb-3">
                                        <label for="choices-fee-input" class="form-label">Amount</label>
                                        <input type="text" v-model="fee_amount" class="form-control" placeholder="$" />
                                    </div>
                                </div>


                                <div class="col-md-2">
                                    <div class="mb-3">
                                        <label for="choices-fee-input" class="form-label">Gst</label>
                                        <input type="text" v-model="fee_gst" class="form-control" placeholder="$" />
                                    </div>
                                </div>

                                <div class="col-md-2" >
                                    <div class="mb-3">
                                        <label  class="form-label">Office</label>
                                        <select class="form-control" v-model="sub_office_code" name="choices-sub-office-input" @change="onOfficeChanged"
                                            id="choices-sub-office-input">
                                        </select>
                                    </div>
                                </div>


                                <div class="col-md-2">
                                    <div class="mb-3 mt-4">
                                        <b-button variant="secondary" type="button" @click="onAddFee">Add Entry</b-button>
                                    </div>
                                </div>

                            </div>
                            <!-- end row-->
                        </div>
                      
                    </div>
                     <!-- end row-->

                   
                   

                    <div class="row mt-5" v-if="is_submitting">
                        <b-alert show variant="danger">
                            <b-spinner variant="danger" label="Spinning" class="me-2"></b-spinner>
                            Submitting Charge Data, please waiting.....
                        </b-alert>
                    </div>

                    <hr class="my-4 mb-3" />
                    <div class="row mt-5"></div>


                    <div class="row mt-5">
                        <b-tabs class="mt-n5 mt-5" content-class="card card-body mt-3" nav-class="nav-tabs-custom"
                            nav-wrapper-class="nav-tabs-custom">
                            <b-tab title="Accounting">
                                <div class="table-responsive">
                                    <div class="table align-middle table-nowrap">
                                        <b-table :items="gl_entry_list" :fields="[{ key: 'GL' }, { key: 'Amount' }, { key: 'Action' }]"
                                            responsive="sm" :per-page="gl_entry_list.length" class="table-check">

                                            <template #cell(GL)="data">
                                                {{ data.item.gl_number + '('+data.item.gl_label+')' }}
                                            </template>
                                            <template #cell(Amount)="data">
                                                ${{ data.item.amount.toLocaleString() }}
                                            </template>
                                            <template #cell(Action)="data">
                                                <b-button variant="outline-danger" size="sm" @click="onRemoveFee(data.index)" >
                                                <i class="mdi mdi-close"></i>
                                                </b-button>
                                            </template>

                                        </b-table>
                                    </div>
                                </div>
                            </b-tab>

                        </b-tabs>

                    </div>
                    <!-- end row-->

                    <div class="row">
                        <div class="col-md-12">
                            <div class="d-flex flex-wrap gap-2 justify-content-center d-flex align-items-center">
                                <b-button variant="secondary" type="button" @click="$router.push({name : 'ap-general'})">Cancel</b-button>
                                <b-button variant="primary" type="submit"  :disabled="is_submitting== true">Submit</b-button>
                            </div>
                        </div>
                       
                    </div>
                    <!-- end row-->


                </form>
            </div>
        </div>
        <!-- end card -->
    </Layout>
</template>

<style scoped>
  #invoice_dropzone {
    letter-spacing: 0.2px;
    height: 150px;
  }
</style>